import React, { useState, useEffect, useContext } from 'react'
import { context } from "../../Context";
import { FLOORPLAN_LEVEL } from "../../constants";

import ImageWithDisclaimer from '../../components/ImageWithDisclaimer';
import ParagraphRow from '../../components/ParagraphRow';
import ParagraphImageRow from '../../components/ParagraphImageRow';
import FloorPlanRow from '../../components/FloorPlanRow'

import "./style.scss";

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
function Tabs({data}) {
    const { state, dispatch } = useContext(context);
    const [tab, setTab] = useState(0)
    const [subPageContent, setSubPageContent] = useState()
    const toggleTab = (tab) => {
        dispatch({
            type: FLOORPLAN_LEVEL,
            payload: 0
        });
        setImage(data[tab].imageWithDisclaimers)
        setTab(tab)
        setSubPageContent(data[tab].subPageContent)
    }

    const [image, setImage] = useState()
    useEffect(() => {
        setImage(data[0].imageWithDisclaimers)
        setSubPageContent(data[0].subPageContent)
        setIsLoading(false)
    }, [data]);

    useEffect(() => {
        setImage(data[tab].imageWithDisclaimers)
        setSubPageContent(data[tab].subPageContent)
    }, [state.language, data])
    
    
    const [isLoading, setIsLoading] = useState(true);
    if (isLoading) {
        return <>Loading...</>;
    }

    return (
        <>
            <div className="tabsWithImage">
                {
                    image != undefined &&
                    <ImageWithDisclaimer data={image} />
                }
                <div className="tabs">
                    {
                        data.map((item, i) => {
                            return (
                                <button className={`${tab === i? 'active': ''}`} onClick={() => toggleTab(i)} key={i}>
                                    <span>{item.subPageName}</span>
                                </button>
                            )
                        })
                    }
                </div>
            </div>

            <div className={`tabContent ${state.toggle_popup? 'popup': ''}`}>
                {
                    subPageContent.map((item, i) => {
                        if (item.type == "paragraphRow") {
                            return (
                                <ParagraphRow key={i} data={item.data.paragraph} />
                            )
                        }

                        if (item.type == "paragraphImageRow") {
                            return (
                                <ParagraphImageRow key={i} data={item.data} />
                            )
                        }

                        if (item.type == "floorPlanRow") {
                            return (
                                <FloorPlanRow key={i} data={item.data} />
                            )
                        }
                    })
                }
            </div>
        </>
        
    )
}


export default Tabs;