import React, { useState } from "react";
import parse from 'html-react-parser';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import "./style.scss";

import { useTranslation } from 'react-i18next';

export default function Disclaimer({data}) {
    const { t } = useTranslation();

    const [disclaimer, setDislaimer] = useState();

    const toggleDisclaimer = (content) => {
        if (content) {
            setDislaimer(content)
        } else {
            setDislaimer()
        }
    }

    return (
        <div className="Disclaimer">
            {
                data &&
                <button className={`btnDisclaimer ${disclaimer? 'active': ''}`} onClick={() => toggleDisclaimer(data)}>{t("disclaimer")}</button>
            }
            {
                disclaimer &&
                <aside>
                    <button onClick={() => toggleDisclaimer()} />
                    <h3>{t("disclaimer")}</h3>
                    <SimpleBar autoHide={false} style={{ maxHeight: 130, paddingRight: 20 }}>
                        {parse(disclaimer)}
                    </SimpleBar>
                </aside>
                    
                }
                
        </div>
    )
}