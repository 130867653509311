import React, { useState, useContext } from 'react'
import parse from 'html-react-parser';
import {CSSTransition} from 'react-transition-group';
import { context } from "../../Context";
import { TOGGLE_POPUP, FLOORPLAN_LEVEL } from "../../constants";
import { useTranslation } from 'react-i18next';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import CompassIcon from '../../components/CompassIcon';
import ZoomButton from '../../components/ZoomButton';
import ExpandableButton from "../../components/ExpandableButton";

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import "./style.scss";

export default function FloorPlanRow({data}) {
    const { state, dispatch } = useContext(context);
    const { t } = useTranslation();

    const [floorplanMenuToggleStatus, setFloorplanMenuToggleStatus] = useState(false)

    const [floorPlanTable, setFloorPlanTable] = useState(false)
    const [floorPlanInfo, setFloorPlanInfo] = useState()
    const [popupContent, setPopupContent] = useState()
    const togglePopup = (data) => {
        setFloorPlanTable(!floorPlanTable)
        dispatch({
            type: TOGGLE_POPUP,
            payload: !state.toggle_popup
        });
        // if (state.responsive_mode)
        //     document.body.classList.toggle('modal-active');
        setPopupContent(data)
    }
    const updateFloorPlanInfo = (data) => {
        setFloorPlanInfo(data)
    }

    const [disclaimer, setDislaimer] = useState();
    const toggleDisclaimer = (content) => {
        if (content) {
            setDislaimer(content)
        } else {
            setDislaimer()
        }
    }



    const toggleFloorplan = (data) => {
        dispatch({
            type: FLOORPLAN_LEVEL,
            payload: data
        });
        setFloorPlanTable(false)
        setFloorplanMenuToggleStatus(false)
    }

    const toggleFloorplanMenu = () => {
        setFloorplanMenuToggleStatus(!floorplanMenuToggleStatus)
    }
    return (
        <div className='FloorPlanRow'>
            <section className='selection'>
                {
                    data.floorPlanName &&
                        <h2>{parse(data.floorPlanName)}</h2>
                }
                {/* Floorplan Menu */}
                <nav>
                    <button onClick={() => toggleFloorplanMenu()}>{data.listedFloorPlanItems[state.floorplan_level].listItemName}</button>
                    <CSSTransition in={floorplanMenuToggleStatus} timeout={0} classNames="transitionSlideDown">
                        <div>
                            {
                                data.listedFloorPlanItems.map((item, i) => {
                                    return (
                                        <button
                                            className={`${i === state.floorplan_level ? 'active' : ''} ${item.isHighlight? 'hightlight': ''}`}
                                            onClick={() => {
                                                updateFloorPlanInfo(data.listedFloorPlanItems[i].floorPlans[0])
                                                toggleFloorplan(i)
                                            }}
                                            key={i}
                                        >
                                            {item.listItemName}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </CSSTransition>
                </nav>
            </section>
            {
                floorPlanInfo != undefined && floorPlanInfo.infoTextFields.length > 0 &&
                    <section className='detail'>
                        {
                            floorPlanInfo.infoTextFields.map((info, i) => {
                                return (
                                    <div key={i}>
                                        <h4>{info.fieldName}</h4>
                                        <span>{parse(info.fieldContent)}</span>
                                    </div>
                                )
                            })
                        }
                    </section>
            }
            <div className={`floorplans ${floorPlanInfo != undefined && floorPlanInfo.infoTextFields.length > 0 ? 'withInfo' : ''}`}>
                <Swiper
                    modules={[Pagination]}
                    slidesPerView={1}
                    pagination={{clickable: true}}
                    centeredSlides
                    height='100%'
                    onUpdate={(e) => {
                        updateFloorPlanInfo(data.listedFloorPlanItems[state.floorplan_level].floorPlans[0])
                    }}
                    onInit={(e) => {
                        updateFloorPlanInfo(data.listedFloorPlanItems[state.floorplan_level].floorPlans[0])
                    }}
                    onSlideChange={(e) => {
                        setFloorPlanTable(false)
                        
                    }}
                    onSlideChangeTransitionEnd={(e) => {
                        updateFloorPlanInfo(data.listedFloorPlanItems[state.floorplan_level].floorPlans[e.realIndex])
                    }}
                    >
                        {
                            data.listedFloorPlanItems[state.floorplan_level].floorPlans.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <div className='floorplanimage'>
                                        <picture>
                                            <source media="(min-width: 821px)" srcSet={item.imageDesktop.Source + '?format=webp'} />
                                            <img src={item.imageMobile.Source + '?format=webp'} alt={item.imageName}  loading="lazy" />
                                        </picture>
                                        
                                        {
                                            item.compassDegree != undefined &&
                                            <CompassIcon degree={item.compassDegree} />
                                        }

                                        {
                                            item.floorLevel &&
                                            <span className='floorlevel'>{item.floorLevel}</span>
                                        }
                                        
                                        {
                                            item.zoomable &&
                                            <ZoomButton imagePath={item.imageDesktop.Source} imageWidth={item.imageDesktop.Width} compress={item.compassDegree}/>
                                        }

                                        {
                                            !disclaimer && item.disclaimer &&
                                            <button className='btnDisclaimer' onClick={() => toggleDisclaimer(item.disclaimer)}>{t("disclaimer")}</button>
                                        }
                                        {
                                            disclaimer &&
                                            <aside className='disclaimers'>
                                                <button onClick={() => toggleDisclaimer()} />
                                                <h3>{t("disclaimer")}</h3>
                                                <SimpleBar forceVisible="y" autoHide={false} style={{ maxHeight: 150, paddingRight: 20 }}>    
                                                    {parse(disclaimer)}
                                                </SimpleBar>
                                            </aside>
                                        }
                                    </div>
                                    {
                                        item.infoTable.buttonText &&
                                        <ExpandableButton text={item.infoTable.buttonText} click={() => togglePopup(item.infoTable.table)} />
                                    }
                                </SwiperSlide>
                            ))
                        }
                </Swiper>
            </div>
            {
                floorPlanInfo != undefined && floorPlanInfo.infoTextFields.length > 0 &&
                    <section className='detail'>
                        {
                            floorPlanInfo.infoTextFields.map((info, i) => {
                                return (
                                    <div key={i}>
                                        <h4>{info.fieldName}</h4>
                                        <span>{parse(info.fieldContent)}</span>
                                    </div>
                                )
                            })
                        }
                        {
                            floorPlanInfo && floorPlanInfo.infoTable.buttonText &&
                            <ExpandableButton text={floorPlanInfo.infoTable.buttonText} click={() => togglePopup(floorPlanInfo.infoTable.table)} />
                        }
                    </section>
            }
            {
                <aside className={`${floorPlanTable == true? 'active': ''}`}>
                    {
                        popupContent &&
                        <SimpleBar forceVisible="y" autoHide={false} style={{ paddingRight: 20 }}>
                            <button onClick={() => togglePopup()} />
                            {parse(popupContent)}
                        </SimpleBar>
                    }
                </aside>
            }
        </div>
    )
}