import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import SlidePrevButton from '../../components/SlidePrevButton'
import SlideNextButton from '../../components/SlideNextButton'
import ImageWithDisclaimer from '../../components/ImageWithDisclaimer';
import Paragraph from '../../components/Paragraph';

import "./style.scss";

export default function ParagraphImageRow({data}) {
    const [visibility, setVisibility] = useState(false);

    const [totalSlides, setTotalSlides] = useState(1)
    const [currentSlide, setCurrentSlide] = useState(1)

    return (
        <div className={`paragraphImageRow ${data.textOnLeftSide == true? 'flip': ''}`}>
            <div className='left'>
                <Swiper
                    watchOverflow
                    onSwiper={(swiper) => {
                        setTotalSlides(swiper.slides.length)
                    }}
                    onUpdate={(swiper) => {
                        setTotalSlides(swiper.slides.length)
                    }}
                    onSlideChange={(e) => {
                        setCurrentSlide(e.realIndex + 1)
                    }}
                    >
                        {
                            totalSlides > 1 && 
                                <>
                                    <SlidePrevButton className={`${currentSlide == 1? 'disabled': ''}`}  />
                                    <SlideNextButton className={`${currentSlide == totalSlides? 'disabled': ''}`}/>
                                </>
                        }
                        
                        {
                            data.imageWithDisclaimers.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <LazyLoadComponent>
                                        <ImageWithDisclaimer data={item} />
                                    </LazyLoadComponent>
                                </SwiperSlide>
                            ))
                        }
                </Swiper>
            </div>
            <div className={`right ${visibility === true? 'transition': ''}`}>
                <VisibilitySensor partialVisibility active={!visibility} onChange={(isVisible) => {
                    setVisibility(isVisible)
                }}>
                    <Paragraph header={data.paragraph.header} contentText={data.paragraph.contentText} />
                </VisibilitySensor>
            </div>
        </div>
    )
}