import React from 'react'
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import ImageWithDisclaimer from '../../components/ImageWithDisclaimer';

import "./style.scss";

export default function ImageRow({data}) {
    return (
        <div className='ImageRow'>
            <Swiper
                modules={[Navigation]}
                navigation={data.imageWithDisclaimers.length > 1? true: false}
                centeredSlides
                loop={data.imageWithDisclaimers.length > 1? true: false}
                breakpoints={{
                    320: {
                        slidesPerView: 1
                    },
                    821: {
                        slidesPerView: data.imageWithDisclaimers.length > 1? 1.3: 1
                    }
                }}
                >
                    {
                        data.imageWithDisclaimers.map((item, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <ImageWithDisclaimer data={item} />
                                </SwiperSlide>
                            )
                        }) 
                    }
            </Swiper>
        </div>
        
    )
}