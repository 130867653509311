import React from 'react'
import parse from 'html-react-parser';
import PropTypes from "prop-types"

import "./style.scss";

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
function Paragraph({header, contentText}) {
    return (
        <div className='paragraph'>
            {
                header &&
                    <h2>{parse(header)}</h2>
            }
            {
                contentText &&
                    <>
                        {parse(contentText)}
                    </>
            }
        </div>
    )
}

Paragraph.propTypes = {
    header: PropTypes.string,
    /**
     * Rich text
     */
    contentText: PropTypes.string,
}

export default Paragraph;