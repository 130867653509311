import React, { useState, useEffect, useContext } from 'react'
import { context } from "../../Context";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import "./style.scss";

export default function NavSiblings() {
    const { state, dispatch } = useContext(context);
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);

    const [ prev, setPrev] = useState();
    const [ next, setNext] = useState();
    
    useEffect(() => {

        
        state.navigation_menu.map((item, i) => {
            if (item.Url == state.current_page) {
                
                if (i == 0) {
                    setPrev(state.navigation_menu[state.navigation_menu.length - 1])
                } else {
                    setPrev(state.navigation_menu[i - 1])
                }
                
                if (i == state.navigation_menu.length - 1) {
                    setNext(state.navigation_menu[0])
                } else {
                    setNext(state.navigation_menu[i + 1])
                }

                setIsLoading(false);
            }
        })
        
    }, [state.navigation_menu, state.current_page]);
    
    if (isLoading) {
        return <></>;
    }

    return (
        <nav className="NavSiblings">
            <Link className='prev' to={'/' + state.language + '/' + prev.Url}><span>{t("PreviousChapter")}</span>{prev.Name}</Link>
            <Link className='next' to={'/' + state.language + '/' + next.Url}><span>{t("NextChapter")}</span>{next.Name}</Link>
        </nav>
    )
}