import React, { useState, useEffect, useContext, useCallback } from 'react'
import {CSSTransition} from 'react-transition-group';
import axios from 'axios';

import { context } from "../../Context";
import { TOGGLE_MODAL, LOGO, PAGE_TRANSITION, SET_LANGUAGE, TOGGLE_INFORMATION_MENU, NAVIGATION_MENU, TOGGLE_NAVIGATION_MENU } from "../../constants";
import {
    Link,
    useNavigate,
    NavLink
} from "react-router-dom";

import InformationMenu from '../InformationMenu'
import MobileMenu from '../MobileMenu'
import "./style.scss";

export default function MainNavigation() {
    
    const { state, dispatch } = useContext(context);
    const [isLoading, setIsLoading] = useState(true);
    
    // Retrieve 
    const [documents, setDocuments] = useState()
    const getNav = async () => {
        const response = await axios.get(
            process.env.API_URL + "/hkri?lang=" + state.language
        );
        setDocuments(response.data.documentContainer)
        dispatch({
            type: LOGO,
            payload: response.data.brandImage
        });
        dispatch({
            type: NAVIGATION_MENU,
            payload: response.data.navigations
        });
        setIsLoading(false);
    };
    useEffect(() => {  
        updateActiveLanguage(state.language)
        if (state.language != undefined) {
            getNav();
        }
    }, [state.language]);

    let navigate = useNavigate();

    const [languageDropdown, setLanguageDropdown] = useState(false)
    const [activeLanguage, setActiveLanguage] = useState()
    const updateActiveLanguage = (language) => {
        switch (language) {
            case "tc":
                setActiveLanguage("繁")
                break;
            case "sc":
                setActiveLanguage("简")
                break;
            case "en":
                setActiveLanguage("EN")
                break;
            default:
                setActiveLanguage("繁")
                break;
        }
        
    }
    const toggleLanguage = (language) => {
        dispatch({
            type: SET_LANGUAGE,
            payload: language
        });
        setLanguageDropdown(!languageDropdown)
        updateActiveLanguage(language)

    }
    
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            document.body.classList.remove('modal-active');
            dispatch({
                type: TOGGLE_MODAL,
                payload: false
            });
        }
    }, []);
    
    useEffect(() => {
        document.addEventListener("keydown", escFunction);
        return () => {
            document.removeEventListener("keydown", escFunction);
        };
    }, [escFunction]);

    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        const adjustHeaderSize = () => {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop
            
            if (winScroll >= 145) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
        // Clean up
        window.removeEventListener('scroll', adjustHeaderSize);
        window.addEventListener('scroll', adjustHeaderSize, { passive: true });
        return () => {
            window.removeEventListener('scroll', adjustHeaderSize);
        }
    }, []);

    const [responsiveMode, setResponsiveMode] = useState(false);
    useEffect(() => {
        const handleWindowResize = () => {
            if (window.innerWidth <= 1194 || (!!navigator.maxTouchPoints && window.matchMedia("(orientation: landscape)").matches)) {
                setResponsiveMode(true)
            } else {
                setResponsiveMode(false)
                setMobileMenu(false)
            }
        };
        handleWindowResize()
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        }
    }, []);

    const gotoPage = (e, page) => {
        e.preventDefault();

        // Change the url
        navigate('/' + state.language + '/' + page);
        if (mobileMenu)
            setMobileMenu(!mobileMenu)
        // Start the fade out animtation
        dispatch({
            type: PAGE_TRANSITION,
            payload: true
        });

        document.body.classList.remove('modal-active');
        
        // Start the fade in animation
        setTimeout(function() {
            dispatch({
                type: PAGE_TRANSITION,
                payload: false
            });
        }, 300)
    }
    
    const [mobileMenu, setMobileMenu] = useState(false);
    const toggleMenu = () => {
        
        dispatch({
            type: TOGGLE_NAVIGATION_MENU,
            payload: !state.toggle_navigation_menu
        });
        if(state.toggle_navigation_menu)
            document.body.classList.remove('modal-active');
        else
            document.body.classList.add('modal-active');
    }

    const openInformationMenu = () => {
        dispatch({
            type: TOGGLE_INFORMATION_MENU,
            payload: true
        });
    }

    const clickLogo = () => {
        dispatch({
            type: TOGGLE_NAVIGATION_MENU,
            payload: false
        });
    }

    if (isLoading) {
        return <></>;
    }

    return (
        <>
            <header className={`${scrolled === true? 'smaller': ''} ${state.toggle_popup == true? 'hide': ''}`}>
                {
                    responsiveMode == true &&

                        <button className='btnInformation' onClick={() => openInformationMenu()}>{documents.documentGroupName}</button>
                }
                <Link to={`/` + state.language} className='logo' title='Logo' onClick={() => clickLogo()}>
                    <img src={state.logo.Source} width="300" height="56" alt={state.logo.AltText || "Villa Lucca 林海山城"} />
                </Link>
                {
                    <div className='menu'>
                        <button className={`btnMenu ${state.toggle_navigation_menu === true? 'active': ''}`} onClick={() => toggleMenu()} aria-label="Menu" />
                    </div>       
                }
                {
                    responsiveMode == false &&
                    <section>
                        <button className='btnInformation' onClick={() => openInformationMenu()}>{documents.documentGroupName}</button>
                        <nav className='main'>
                            {
                                state.navigation_menu.map((item, i) => {
                                    return (
                                        <NavLink key={i} to={item.Url} onClick={(e) => gotoPage(e, item.Url)}>{item.Name}</NavLink>
                                    )
                                })
                            }
                        </nav>
                        <div className={`toggleLanguages ${languageDropdown === true? 'active': ''}`}>
                            <button className="currentLanguage" onClick={() => setLanguageDropdown(!languageDropdown)}>{activeLanguage}</button>
                            <nav className='languages'>
                                {
                                    state.language != "tc" &&
                                        <Link to={`/tc/` + state.current_page} onClick={() => {
                                            toggleLanguage('tc')   
                                        }}>繁</Link>
                                }
                                {
                                    state.language != "en" &&
                                    <Link to={`/en/` + state.current_page} onClick={() => {
                                            toggleLanguage('en')
                                        }}>EN</Link>
                                }
                                {
                                    state.language != "sc" &&
                                    <Link to={`/sc/` + state.current_page} onClick={() => {
                                            toggleLanguage('sc')
                                        }}>简</Link>
                                }
                            </nav>
                        </div>
                    </section>
                }
            </header>
            {
                <CSSTransition
                    in={state.information_menu}
                    timeout={0}
                    classNames="transitionSlideRight"
                    >
                        <InformationMenu data={documents} />
                </CSSTransition>
            }
            {
                
                    <CSSTransition
                        in={state.toggle_navigation_menu}
                        timeout={0}
                        classNames="transitionSlideLeft"
                        >
                            <MobileMenu />
                    </CSSTransition>
            }
        </>
    )
}