import React, { useState, useEffect, useContext } from "react"
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { context } from "../../Context";
import { TOGGLE_MODAL } from "../../constants";
import useFetchData from '../../hooks/use-fetchData'

import SkeletonLoader from '../../components/SkeletonLoader'
import Meta from '../../components/Meta'
import Disclaimer from '../../components/Disclaimer'
import ChapterBanner from '../../components/ChapterBanner';

import "./style.scss";

export default function Gallery() {
    const { state, dispatch } = useContext(context);
    const { t } = useTranslation();

    const { seoData, pageData, isLoading } = useFetchData('gallery');

    useEffect(() => {
        if (pageData.length > 0) {
            toggleTab(0, pageData[1].data.galleryTabContainer[0].tabContent)
        }
    }, [pageData]);
    const [tab, setTab] = useState(0)
    const [gallery, setGallery] = useState()
    const toggleTab = (tab, content) => {
        setTab(tab)
        setGallery(content)
    }

    const openModal = (type, content) => {
        document.body.classList.add('modal-active');
        dispatch({
            type: TOGGLE_MODAL,
            payload: {
                type: type,
                content: content
            }
        });  
    }

    
    if (isLoading) {
        return (
            <SkeletonLoader />
        );
    }

    return (
        <>
            <Meta data={seoData} />
            {
                pageData.map((component, i) => {
                    if (component.type == "chapterBannerRow") {
                        return (
                            <ChapterBanner key={i} data={component.data} prev="masters" next="luxury-design" />
                        )
                    }

                    if (component.type == "galleryContainer") {
                        return (
                            <div className="galleries" key={i}>
                                <div className="tabs">
                                    {
                                        component.data.galleryTabContainer.map((gallerytab, j) => {
                                            return (
                                                <button className={`${tab === j? 'active': ''}`} onClick={() => toggleTab(j, gallerytab.tabContent)} key={j}>{gallerytab.tabName}</button>
                                            )
                                        })
                                    }
                                </div>
                                <div className="gallery">
                                    {
                                        gallery.map((galleryItem, i) => {
                                            return (
                                                <div className="galleryItem" key={i} onClick={() => galleryItem.type == 'galleryImage'? openModal('gallery', {index: i, gallery: gallery}): openModal('video', {videoCover: galleryItem.data.videoCover, videoLink: galleryItem.data.videoLink, videoData: galleryItem.data})}>
                                                    <figure>
                                                        <img src={galleryItem.data.thumbnail.Source} />
                                                        {
                                                            galleryItem.data.imageName &&
                                                            <figcaption>{parse(galleryItem.data.imageName)}</figcaption>
                                                        }
                                                        {
                                                            galleryItem.data.videoName &&
                                                            <figcaption>{parse(galleryItem.data.videoName)}</figcaption>
                                                        }
                                                    </figure>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }

                    // Hard-coded value that display disclaimer in video gallery tab
                    if (component.type == "disclaimer" && tab == 1) {
                        return (
                            <Disclaimer key={i} data={component.data.Content} />
                        )
                    }
                })
            }
        </>
    )
}