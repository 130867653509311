import React from "react";
import { BrowserRouter } from "react-router-dom";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container);

import Provider from "./Context";
import "./App.scss";
import App from "./App";

import { HelmetProvider } from 'react-helmet-async';

root.render(
    <Provider>
        <HelmetProvider>
            <BrowserRouter basename={process.env.PAGE_URL}>
                <App />
            </BrowserRouter>
        </HelmetProvider>
    </Provider>
)