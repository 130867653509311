import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import parse from 'html-react-parser';
import { context } from "../../Context";
import { TOGGLE_MODAL } from "../../constants";

import "./style.scss";

export default function Footer() {

    const { state, dispatch } = useContext(context);
    const [isLoading, setIsLoading] = useState(true);

    // Retrieve 
    const [pageData, setPageData] = useState()   
    const fetchPageData = async () => {
        const response = await axios.get(
            process.env.API_URL + "/hkri?lang=" + state.language
        );
        setPageData(response.data.footer)
        setIsLoading(false);

        // Information to purchaser appears when first lands, and language is changed
        if (process.env.ENABLE_LANDING_POPUP == 'true') {
            openModal("text", {
                title: response.data.footer.termsDocuments[0].termsName,
                content: response.data.footer.termsDocuments[0].termsContent
            })
        }
    };
    useEffect(() => {  
        if (state.language != undefined) {
            fetchPageData();
        }
    }, [state.language]);

    const openModal = (type, content) => {
        document.body.classList.add('modal-active');
        dispatch({
            type: TOGGLE_MODAL,
            payload: {
                type: type,
                content: content
            }
        });
    }

    if (isLoading) {
        return <></>;
    }
    return (
        <footer className={`${state.toggle_popup == true? 'hide': ''}`}>
            <img className='logo' src={state.logo.Source} width="268" height="50" alt={state.logo.AltText || "Villa Lucca 林海山城"} />
            {parse(pageData.addressContent)}
            <div className='contact'>
                <div className='hotline'>
                    <h3>{parse(pageData.hotlineText)}</h3>
                    {parse(pageData.hotlineContent)}
                </div>
                <div className='email'>
                    <h3>{parse(pageData.emailText)}</h3>
                    {parse(pageData.emailContent)}
                </div>
                
            </div>
            <div className='partners'>
                {
                    pageData.companyIcon.map((item, i) => {
                        return (
                            <a href={item.iconLink.Url} target={item.iconLink.Target} key={i}>
                                <img src={item.iconImage.Source + '?format=webp'} width={item.iconImage.Width} height={item.iconImage.Height} alt={item.iconImage.AltText} />
                            </a>
                            
                        )
                    })
                }
            </div>
            <nav>
                {
                    pageData.termsDocuments.map((item, i) => {
                        return (
                            <button key={i} onClick={() => openModal('text', {title: item.termsName, content: item.termsContent})}>{item.termsName}</button>
                        )
                    })
                }
            </nav>
            <div className='copyright'>
                {parse(pageData.copyrightContent)}
                {parse(pageData.lastUpdateContent)}
            </div>
        </footer>
        
    )
}