import React, { useState, useEffect, useContext } from 'react'
import { context } from "../../Context";
import { TOGGLE_MODAL, TOGGLE_INFORMATION_MENU } from "../../constants";

import "./style.scss";

function InformationMenu({data}) {
    const { state, dispatch } = useContext(context);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (data != undefined)
            setIsLoading(false)
    }, [data]);

    const closeInformationMenu = () => {
        dispatch({
            type: TOGGLE_INFORMATION_MENU,
            payload: false
        });
    }

    const openModal = (type, content) => {
        document.body.classList.add('modal-active');
        dispatch({
            type: TOGGLE_MODAL,
            payload: {
                type: type,
                content: content
            }
        });  
    }
    
    if (isLoading) {
        return <></>;
    }
    return (
        <section className={`InformationMenu`} >
            <button className='btnClose' onClick={() => closeInformationMenu()} aria-label="Close" />
            <h2>{data.documentGroupName}</h2>
            {
                data.documentTabs.map((item, i) => {
                    return (
                        <button key={i} onClick={() => openModal('document', item)}>{item.documentTabName}</button>
                    )
                })
            }
        </section>
    )
}

export default InformationMenu;