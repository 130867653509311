import React from 'react'
import { useSwiper } from 'swiper/react';

import "./style.scss";

export default function SlideNextButton({className}) {
    const swiper = useSwiper();
    return (
        <button className={`SlideNextButton ${className}`} onClick={() => swiper.slideNext()} />
    );
}