import React from "react"
import useFetchData from '../../hooks/use-fetchData'

import SkeletonLoader from '../../components/SkeletonLoader'
import Meta from '../../components/Meta'
import ChapterBanner from '../../components/ChapterBanner';
import ImageRow from '../../components/ImageRow';
import ParagraphImageRow from '../../components/ParagraphImageRow';

import "./style.scss";

export default function LuxuryDesign() {
    const prev = "gallery"
    const next = "residences"

    const { seoData, pageData, isLoading } = useFetchData('luxury-design');
    
    if (isLoading) {
        return (
            <SkeletonLoader />
        );
    }

    return (
        <>
            <Meta data={seoData} />
            {
                pageData.map((component, i) => {
                    if (component.type == "chapterBannerRow") {
                        return (
                            <ChapterBanner key={i} data={component.data} prev={prev} next={next} />
                        )
                    }

                    if (component.type == "imageRow") {
                        return (
                            <ImageRow key={i} data={component.data} />
                        )
                    }

                    if (component.type == "paragraphImageRow") {
                        return (
                            <ParagraphImageRow key={i} data={component.data} />
                        )
                    }
                })
            }
        </>
    )
}