import {
    SET_LOADING,
    TOGGLE_MODAL,
    SET_LANGUAGE, 
    PAGE_TRANSITION,
    TOGGLE_INFORMATION_MENU,
    NAVIGATION_MENU,
    CURRENT_PAGE,
    RESPONSIVE_MODE,
    TOGGLE_POPUP,
    LOGO,
    FLOORPLAN_LEVEL,
    TOGGLE_NAVIGATION_MENU
} from "./constants";

export const initialState = {
    loading: false,
    modal: false,
    information_menu: false,
    navigation_menu: [],
    current_page: "",
    responsive_mode: false,
    toggle_navigation_menu: false,
    toggle_popup: false,
    logo: "",
    floorplan_level: 0
};

const reducer = (state, action) => {
    switch (action.type) {
        case FLOORPLAN_LEVEL: 
            return {
                ...state,
                floorplan_level: action.payload
            };
        case TOGGLE_POPUP: 
            return {
                ...state,
                toggle_popup: action.payload
            };
        case TOGGLE_NAVIGATION_MENU:
            return {
                ...state,
                toggle_navigation_menu: action.payload
            };
        case TOGGLE_INFORMATION_MENU:
            return {
                ...state,
                information_menu: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case TOGGLE_MODAL:
            return {
                ...state,
                modal: action.payload
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            };
        case PAGE_TRANSITION:
            return {
                ...state,
                transition: action.payload
            };
        case NAVIGATION_MENU:
            return {
                ...state,
                navigation_menu: action.payload
            }
        case CURRENT_PAGE:
            return {
                ...state,
                current_page: action.payload
            }
        case RESPONSIVE_MODE:
            return {
                ...state,
                responsive_mode: action.payload
            }
        case LOGO:
            return {
                ...state,
                logo: action.payload
            }
        default:
            return state;
    }
};

export default reducer;