import React, { useState, useEffect, useContext } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import parse from 'html-react-parser';
import { context } from "../../Context";
import { TOGGLE_MODAL } from "../../constants";
import { useTranslation } from 'react-i18next';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import SlidePrevButton from '../../components/SlidePrevButton'
import SlideNextButton from '../../components/SlideNextButton'
import ImageWithDisclaimer from '../../components/ImageWithDisclaimer'
import CompassIcon from '../../components/CompassIcon';


import "./style.scss";

export default function Modal() {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(context);
    const [currentSlide, setCurrentSlide] = useState(1)
    const [iFrameDisclaimerStatus, setIFrameDisclaimerStatus] = useState();

    const scrollableNodeRef = React.createRef();
    useEffect(() => {
        if (state.modal.type == 'zoom' && scrollableNodeRef.current != null) {
            setTimeout(function() {
                scrollableNodeRef.current.scrollLeft = (state.modal.imageWidth - scrollableNodeRef.current.offsetWidth) / 3
            }, 300)
        }
    }, [state.modal.imageWidth]);

    useEffect(()=>{
        if(state.modal.content){

            if(state.modal.content.disclaimer){
                setIFrameDisclaimerStatus(true);
            }else{
                setIFrameDisclaimerStatus(false);
            }
        }
    })
    
    const closeModal = (e) => {
        document.body.classList.remove('modal-active');
        dispatch({
            type: TOGGLE_MODAL,
            payload: false
        });
    }

    const closeiFrameDisclaimer = () => {
        // setIFrameDisclaimerStatus(false);
        // console.log('iFrameDisclaimerStatus: ', iFrameDisclaimerStatus);
        dispatch({
            type: TOGGLE_MODAL,
            payload: {
                type: state.modal.type, 
                content: {
                    tour: state.modal.content.tour
                }
            }
        });
    }

    const [disclaimer, setDisclaimer] = useState();
    const toggleDisclaimer = (content) => {
        if (content) {
            setDisclaimer(content)
        } else {
            setDisclaimer()
        }
    }




    return (
        <div className={`modal ${state.modal !== false? 'active': ''}`}>
            <div className='mask' onClick={() => closeModal()}></div>
            <div className={`popup ${state.modal.type != undefined && state.modal.type}`}>
                <button className='btnClose' onClick={() => closeModal()} />
                {
                    state.modal.type == 'video' &&
                    <>
                        <video playsInline
                            controls={['PlayPause', 'Volume', 'Fullscreen']}
                            poster={state.modal.content.videoCover.Source}
                        >
                            <source src={process.env.API_URL + state.modal.content.videoLink.Url} type="video/mp4" />
                        </video>
                        {
                            !disclaimer && state.modal.content.videoData.disclaimer &&
                            <button className='btnDisclaimer' onClick={() => toggleDisclaimer(state.modal.content.videoData.disclaimer)}>{t("disclaimer")}</button>
                        }
                        {
                            disclaimer &&
                            <aside>
                                <button onClick={() => toggleDisclaimer()} />
                                <h3>{t("disclaimer")}</h3>
                                <SimpleBar style={{ maxHeight: 130, paddingRight: 20 }}>
                                    {parse(disclaimer)}
                                </SimpleBar>
                            </aside>
                        }
                        {
                            state.modal.content.videoData.videoName &&
                            <h2>{parse(state.modal.content.videoData.videoName)}</h2>
                        }
                    </>
                    

                }
                {
                    state.modal.type == 'text' &&
                    <>
                        <h2>{state.modal.content.title}</h2>
                        <SimpleBar autoHide={false} style={{ maxHeight: '90%', paddingRight: 20 }}>
                            {parse(state.modal.content.content)}
                        </SimpleBar>
                    </>
                }
                {
                    state.modal.type == 'iframe' &&
                    <>
                        {/* <iframe src="https://demo1.dscsol.com/" width="100%" height={500} title='A youtube video on React hooks' /> */}
                        <iframe src={state.modal.content.tour.tourLink.Url} width="100%" height={500} title={state.modal.content.tour.unitLocation} />
                        <aside className={`${iFrameDisclaimerStatus === true ? 'active': ''}`}>
                            <div className='mask' onClick={() => closeModal()}></div>
                            <div className='iframeDisclaimerPopup'>
                                <button className='btnClose' onClick={() => closeiFrameDisclaimer()} />
                                <h2>{t("InformationToPurchaser")}</h2>
                                <SimpleBar autoHide={false} style={{ maxHeight: '90%', paddingRight: 20 }}>
                                    {parse(state.modal.content.tour.tourDisclaimer)}
                                </SimpleBar>
                            </div>
                        </aside>
                    </>
                }
                {
                    state.modal.type == 'zoom' &&
                    <div className='zoomedImage'>
                        <div className='compassIconInPopup'>
                            <CompassIcon degree={state.modal.compress} />
                        </div>
                        <SimpleBar autoHide={false} forceVisible={true} scrollableNodeProps={{ ref: scrollableNodeRef }} style={{ overflow: 'auto', maxHeight: '100%', paddingRight: 20}}>
                            <img src={state.modal.content + '?format=webp'} loading="lazy" />
                        </SimpleBar>
                    </div>
                    
                }
                {
                    state.modal.type == 'document' &&
                    <>
                        <h2>{state.modal.content.documentTabName}</h2>
                        {
                            state.modal.content.documentContent.length > 0?
                                <div className='content'>
                                    <SimpleBar autoHide={false} style={{ paddingRight: 20 }}>
                                    {
                                        state.modal.content.documentContent.map((data, i) => {
                                            return (
                                                <div className='item' key={i}>
                                                    <div className='description'>
                                                        <time>{data.dateString}</time>
                                                        {parse(data.description)}
                                                    </div>
                                                    <div className='download'>
                                                        <a href={data.pdfUrl} target="_blank" rel="noopener noreferrer">{t("Download")}</a>
                                                    </div>
                                                </div>   
                                            )
                                        })
                                    }
                                    </SimpleBar>
                                </div>
                            :
                                <div className='content comingsoon'>
                                    <p>{t("ComingSoon")}</p>
                                </div>
                        }
                    </>
                }
                {
                    state.modal.type == 'gallery' &&
                    <>
                        <div className='slidesCount'>{currentSlide}<span className='total'>{state.modal.content.gallery.length}</span></div>
                        <Swiper
                            autoHeight
                            watchOverflow
                            onInit={(e) => {
                                setCurrentSlide(state.modal.content.index + 1)
                                
                            }}
                            onSwiper={(swiper) => {
                                setTimeout(function() {
                                    swiper.slideTo(state.modal.content.index)
                                }, 300)
                            }}
                            onSlideChange={(e) => {
                                setCurrentSlide(e.activeIndex + 1)
                            }}
                            centeredSlides
                            slidesPerView='auto'
                            >
                                
                                <SlidePrevButton />
                                <SlideNextButton />
                                {
                                    state.modal.content.gallery.map(({data}, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <ImageWithDisclaimer type="gallery" data={data} />
                                                {
                                                    data.imageName &&
                                                        <h2>{data.imageName}</h2>
                                                }
                                                {/* <LazyLoadComponent>
                                                    
                                                </LazyLoadComponent> */}
                                            </SwiperSlide>
                                        )
                                    })
                                }
                        </Swiper>
                    </>
                }
            </div>
        </div>
    )
}