import React, { useEffect, useContext, useCallback } from 'react'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { context } from "../../Context";
import { PAGE_TRANSITION, SET_LANGUAGE, TOGGLE_NAVIGATION_MENU } from "../../constants";
import {
    Link,
    useNavigate,
    NavLink
} from "react-router-dom";

import "./style.scss";

export default function MobileMenu() {
    
    const { state, dispatch } = useContext(context);
    
    let navigate = useNavigate();

    const toggleLanguage = (language) => {
        dispatch({
            type: SET_LANGUAGE,
            payload: language
        });
    }
    
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            document.body.classList.remove('modal-active');
            dispatch({
                type: TOGGLE_NAVIGATION_MENU,
                payload: false
            });  
        }
    }, []);
    useEffect(() => {
        document.addEventListener("keydown", escFunction);
        return () => {
            document.removeEventListener("keydown", escFunction);
        };
    }, [escFunction]);

    const gotoPage = (e, page) => {
        e.preventDefault();
        
        // Change the url
        navigate('/' + state.language + '/' + page);

        // Start the fade out animtation
        dispatch({
            type: PAGE_TRANSITION,
            payload: true
        });
        
        // Start the fade in animation
        setTimeout(function() {
            dispatch({
                type: PAGE_TRANSITION,
                payload: false
            });
        }, 300)

        // Close the mobile menu
        dispatch({
            type: TOGGLE_NAVIGATION_MENU,
            payload: false
        });
        document.body.classList.remove('modal-active');
    }
    

    return (
        <div className='MobileMenu'>
            <SimpleBar>
                <nav className='primary'>
                    {
                        state.navigation_menu.map((item, i) => {
                            return (
                                <NavLink key={i} to={item.Url} onClick={(e) => gotoPage(e, item.Url)}>{item.Name}</NavLink>
                            )
                        })
                    }
                </nav>
                <nav className='languages'>
                    <Link to={`/tc/` + state.current_page} className={`${state.language === 'tc'? 'active': ''}`} onClick={() => {toggleLanguage('tc')}}>繁</Link>
                    <Link to={`/en/` + state.current_page} className={`${state.language === 'en'? 'active': ''}`} onClick={() => {toggleLanguage('en')}}>EN</Link>
                    <Link to={`/sc/` + state.current_page} className={`${state.language === 'sc'? 'active': ''}`} onClick={() => {toggleLanguage('sc')}}>简</Link>
                </nav>
            </SimpleBar>
            
        </div>
    )
}