import React, { useState, useEffect } from "react"
import parse from 'html-react-parser';
import useFetchData from '../../hooks/use-fetchData'

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import SkeletonLoader from '../../components/SkeletonLoader'
import Meta from '../../components/Meta'
import ExpandableButton from "../../components/ExpandableButton";
import ChapterBanner from '../../components/ChapterBanner';
import ImageWithDisclaimer from '../../components/ImageWithDisclaimer';
import ParagraphImageRow from '../../components/ParagraphImageRow';
import CompassIcon from '../../components/CompassIcon';
import Tabs from '../../components/Tabs';

import "./style.scss";

export default function Residences() {
    const prev = "luxury-design"
    const next = "virtual-tour"

    const { seoData, pageData, isLoading } = useFetchData('residences');

    const [bluePrintTable, setBluePrintTable] = useState(false)

    const toggleBluePrintTable = () => {
        if (responsiveMode) {
            if (bluePrintTable == false) {
                //document.body.classList.add('modal-active');
            } else {
                document.body.classList.remove('modal-active');
            }
        }
        setBluePrintTable(!bluePrintTable)
    }

    const [responsiveMode, setResponsiveMode] = useState(false);
    useEffect(() => {
        const handleWindowResize = () => {
            if (window.innerWidth <= 1194 || (!!navigator.maxTouchPoints && window.matchMedia("(orientation: landscape)").matches)) {
                setResponsiveMode(true)
                if (bluePrintTable == true) {
                    //document.body.classList.add('modal-active');
                }
            } else {
                setResponsiveMode(false)
                document.body.classList.remove('modal-active');
            }
        };
        window.addEventListener("load", handleWindowResize);
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("load", handleWindowResize);
            window.removeEventListener("resize", handleWindowResize);
        }
    }, []);


    if (isLoading) {
        return (
            <SkeletonLoader />
        );
    }

    return (
        <>
            <Meta data={seoData} />
            {
                pageData.map((component, i) => {
                    if (component.type == "chapterBannerRow") {
                        return (
                            <ChapterBanner key={i} data={component.data} prev={prev} next={next} />
                        )
                    }
                    
                    if (component.type == "blueprintRow") {
                        return (
                            <div className="blueprint" key={i}>
                                
                                <ImageWithDisclaimer data={component.data.imageWithDisclaimers} />
                                {
                                    component.data.compassDegree != undefined &&
                                        <CompassIcon degree={component.data.compassDegree} />
                                }
                                {
                                    component.data.scaleMeter &&
                                        <img className="scale" src={component.data.scaleMeter.Source} width={component.data.scaleMeter.Width} height={component.data.scaleMeter.Height} />
                                }
                                {
                                    component.data.infoTable &&
                                        <>
                                            <ExpandableButton text={component.data.infoTable.buttonText} click={() => toggleBluePrintTable()} />
                                            <aside className={`${bluePrintTable == true? 'active': ''}`}>
                                                <button onClick={() => toggleBluePrintTable()} />
                                                <SimpleBar autoHide={false}>
                                                    {parse(component.data.infoTable.table)}
                                                </SimpleBar>
                                                
                                            </aside>
                                        </>
                                        
                                }
                            </div>
                            
                        )
                    }

                    if (component.type == "imageRow") {
                        return (
                            <ImageRow key={i} data={component.data} />
                        )
                    }

                    if (component.type == "paragraphImageRow") {
                        return (
                            <ParagraphImageRow key={i} data={component.data} />
                        )
                    }

                    if (component.type == "tabs") {
                        return (
                            <Tabs key={i} data={component.data.subPages} />
                        )
                    }
                })
            }
        </>
    )
}