import React from "react"
import { useTranslation } from 'react-i18next';
import {
    Link
} from "react-router-dom";

import "./style.scss";


export default function App() {
    const { t } = useTranslation();
    
    return (
        <div className="pagenotfound">
           <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>        
        </div>
    )
}