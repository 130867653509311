import React, { useContext } from 'react'
import parse from 'html-react-parser';
import { context } from "../../Context";
import Modal from '../../components/Modal';
import { TOGGLE_MODAL } from "../../constants";
import { useTranslation } from 'react-i18next';

import ImageWithDisclaimer from '../../components/ImageWithDisclaimer';

import "./style.scss";

export default function TourUnitRow({data}) {
    const { state, dispatch } = useContext(context);
    const { t } = useTranslation();

    const openModal = (type, content) => {
        document.body.classList.add('modal-active');
        dispatch({
            type: TOGGLE_MODAL,
            payload: {
                type: type,
                content: content
            }
        });  
    }

    return (
        <div className="tours-container">
            <div className='tours'>
                {
                    data.map((item, i) => {
                        if (item.tourLink != null) {
                            return (
                                <div className='tour link' key={i} onClick={() => {
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        'event': 'VirtualTourClick',
                                        'ClickDetail': item.unitLocation
                                    });
                                    openModal('iframe', {tour: item, disclaimer: true})}
                                }>
                                    <ImageWithDisclaimer data={item.tourCover} />
                                    <h2>{parse(item.unitLocation)}</h2>
                                    <p>{item.unitCategory}</p>
                                    <span>{t("linkVirtualTour")}</span>
                                </div>
                            ) 
                        } else {
                            return (
                                <div className='tour' key={i}>
                                    <ImageWithDisclaimer data={item.tourCover} />
                                    <h2>{parse(item.unitLocation)}</h2>
                                    <p>{item.unitCategory}</p>
                                    <span className='withoutLeftRightBorder'>{t("ComingSoonVirtualTour")}</span>
                                </div>
                            )
                        }
                    })
                        
                        
                }
            </div>
        </div>
    )
}