import React, { useState, useEffect } from "react"
import useFetchData from '../../hooks/use-fetchData'

import SkeletonLoader from '../../components/SkeletonLoader'
import Meta from '../../components/Meta'
import ChapterBanner from '../../components/ChapterBanner';
import TourUnitRow from '../../components/TourUnitRow';
import Disclaimer from '../../components/Disclaimer'

import "./style.scss";

export default function VirtualTour() {
    const { seoData, pageData, isLoading } = useFetchData('virtual-tour');

    const [comingSoon, setComingSoon] = useState(false);
    useEffect(() => {
        if (pageData.length > 0 && pageData[0].type == 'chapterBannerRow') {
            setComingSoon(pageData[0].data.isComingSoon) 
        }
    }, [pageData]);

    if (isLoading) {
        return (
            <SkeletonLoader />
        );
    }

    return (
        <>
            <Meta data={seoData} />
            {
                pageData.map((component, i) => {
                    if (component.type == "chapterBannerRow") {
                        
                        return (
                            // <ChapterBanner key={i} data={component.data} prev="residences" next="location" />
                            <ChapterBanner key={i} data={component.data} prev="residences" next="club-lucca" />
                        )
                    }
                    if (!comingSoon) {
                        if (component.type == "tourUnitRow") {
                            return (
                                <TourUnitRow key={i} data={component.data.tourUnitGallery} />
                            )
                        }
                        if (component.type == "disclaimer") {
                            return (
                                <Disclaimer key={i} data={component.data.Content} />
                            )
                        }
                    }
                    
                })
            }
        </>
    )
}