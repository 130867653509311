import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import enUstrans from './languages/en.json';
import zhSctrans from './languages/sc.json';
import hkTctrans from './languages/tc.json';
import { initReactI18next } from 'react-i18next';


i18n.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources: {
        en: {
            translation: enUstrans
        },
        sc: {
            translation: zhSctrans
        },
        tc: {
            translation: hkTctrans
        }
    },
    lng: 'tc',
    fallbackLng: "tc",
    interpolation: {
        escapeValue: false
    }
})

export default i18n;
