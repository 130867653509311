import React, { useState, useEffect, useContext } from 'react'
import { context } from "../../Context";
import { useTranslation } from 'react-i18next';
import { PAGE_TRANSITION, TOGGLE_MODAL } from "../../constants";
import {
    useNavigate,
    Link
} from "react-router-dom";

import ImageWithDisclaimer from '../../components/ImageWithDisclaimer';

import "./style.scss";

export default function ChapterBanner({data, prev, next}) {
    const { state, dispatch } = useContext(context);
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [opacityScrollDown, setOpacityScrollDown] = useState(1);
    

    useEffect(() => {
        const updateScrollDown = () => {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
            const scrolled = winScroll / height * 20
            setOpacityScrollDown(1 - scrolled)
        }

        // Clean up
        window.removeEventListener('scroll', updateScrollDown);
        window.addEventListener('scroll', updateScrollDown, { passive: true });


        return () => window.removeEventListener('scroll', updateScrollDown);


    }, []);

    const scrollToContent = (e) => {
        window.scrollBy({
            top: window.innerHeight,
            behavior: 'smooth'
        })
    }

    const gotoPage = (e, page) => {
        e.preventDefault();
        // Change the url
        navigate('/' + state.language + '/' + page);
        // Start the fade out animtation
        dispatch({
            type: PAGE_TRANSITION,
            payload: true
        });
        // Start the fade in animation
        setTimeout(function() {
            dispatch({
                type: PAGE_TRANSITION,
                payload: false
            });
        }, 300)
    }

    const openModal = (type, content) => {
        document.body.classList.add('modal-active');
        dispatch({
            type: TOGGLE_MODAL,
            payload: {
                type: type,
                content: content
            }
        });  
    }
    
    return (
        <div className='fullscreen'>
            <div className={`background ${state.transition === true? 'transition': ''}`}>
                <ImageWithDisclaimer data={data.imageWithDisclaimers} lazyload={false} />
                <Link className='navPrev' title='Previous' to={'/' + state.language + '/' + prev} onClick={(e) => gotoPage(e, prev)} />
                <h2>
                    {data.centerText}
                    {
                        data.isComingSoon &&
                        <span>{t("ComingSoonVirtualTour")}</span>
                    }
                    {
                        data.videoCover.Source != "" &&
                        <button onClick={() => openModal('video', {videoCover: data.videoCover, videoLink: data.videoLink})}>{t("watchvideo")}</button>
                    }
                </h2>
                <Link className='navNext' title='Next' to={'/' + state.language + '/' + next} onClick={(e) => gotoPage(e, next)} />
            </div>
            
            <button className="btnScrollDown" style={{opacity: opacityScrollDown}} onClick={scrollToContent}>{t("ScrollDown")}</button>
        </div>
    )
}