export const TOGGLE_MODAL = "TOGGLE MODAL";
export const TOGGLE_NAVIGATION_MENU = "TOGGLE_NAVIGATION_MENU"
export const TOGGLE_INFORMATION_MENU = "TOGGLE_INFORMATION_MENU"
export const TOGGLE_POPUP = "TOGGLE_POPUP"

export const SET_LOADING = "SET LOADING";
export const SET_LANGUAGE = "SET LANGUAGE";
export const PAGE_TRANSITION = "PAGE TRANSITION";

export const NAVIGATION_MENU = "NAVIGATION_MENU"
export const LOGO = "LOGO"
export const CURRENT_PAGE = "CURRENT_PAGE"
export const FLOORPLAN_LEVEL = "FLOORPLAN_LEVEL"

export const RESPONSIVE_MODE = "RESPONSIVE_MODE"
