import React, { createContext, useReducer } from "react";
import reducer, { initialState } from "./reducer";

export let context = createContext({});

export default function Provider({ children }) {

    const [state, dispatch] = useReducer(reducer, initialState);
    const values = {
        state,
        dispatch
    };

    return (
        <>
            <context.Provider value={values}>
                {children}
            </context.Provider>
        </>
    );
}