import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser';
import VisibilitySensor from 'react-visibility-sensor';
import { useTranslation } from 'react-i18next';

import ZoomButton from '../../components/ZoomButton';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import "./style.scss";

export default function ImageWithDisclaimer({type, data, lazyload}) {
    const { t } = useTranslation();
    
    const [visibility, setVisibility] = useState(false);
    const [disclaimer, setDislaimer] = useState();
    useEffect(() => {  
        if (disclaimer) {
            setDislaimer(data.disclaimer)
        }
    }, [data]);

    const toggleDisclaimer = (content) => {
        if (content) {
            setDislaimer(content)
        } else {
            setDislaimer()
        }
    }
    
    return (
        <VisibilitySensor partialVisibility active={!visibility} onChange={(isVisible) => {
            setVisibility(isVisible)
        }}>
            <div className={`ImageWithDisclaimer ${visibility === true? 'transition': ''}`}>
                <figure>
                    {
                        type == 'gallery' &&
                            <img src={data.originImage.Source + '?format=webp'} alt={data.originImage.imageName} width={data.originImage.Width} height={data.originImage.Height}/>
                    }
                    {
                        type == 'location' &&
                            <img src={data.map.Source + '?format=webp'} alt={data.mapName} width={data.map.Width} height={data.map.Height} loading="lazy" />
                    }
                    {
                        type == undefined &&
                        <picture>
                            {
                                data.imageDesktop.Source != "" &&
                                    <source media="(min-width: 821px)" srcSet={data.imageDesktop.Source + '?format=webp'} width={data.imageDesktop.Width} height={data.imageDesktop.Height} />
                            }
                            {
                                data.imageMobile.Source != "" &&
                                    <source media="(min-width: 429px)" srcSet={data.imageTablet.Source + '?format=webp'} width={data.imageTablet.Width} height={data.imageTablet.Height} />
                            }
                            {
                                data.imageMobile.Source != "" ?
                                    <img src={data.imageMobile.Source + '?format=webp'} alt={data.imageName} width={data.imageMobile.Width} height={data.imageMobile.Height} loading={`${lazyload === true? 'lazy': ''}`} />
                                :
                                    <img src={data.imageDesktop.Source + '?format=webp'} alt={data.imageName} width={data.imageDesktop.Width} height={data.imageDesktop.Height} loading={`${lazyload === true? 'lazy': ''}`} />
                            }
                        </picture>
                    }
                    {
                        !disclaimer && data.disclaimer &&
                            <button className='btnDisclaimer' onClick={() => toggleDisclaimer(data.disclaimer)}>{t("disclaimer")}</button>
                    }
                    {
                        // To avoid the caption to display in the gallery detail, we check if the type is not gallery
                        data.imageName && type != 'gallery' &&
                            <figcaption>{parse(data.imageName)}</figcaption>
                    }
                </figure>
                {
                    data.zoomable &&
                    <ZoomButton imagePath={data.imageDesktop.Source} imageWidth={data.imageDesktop.Width} />
                }
                {
                    disclaimer &&
                    <aside>
                        <button onClick={() => toggleDisclaimer()} />
                        <h3>{t("disclaimer")}</h3>
                        <SimpleBar forceVisible="y" autoHide={false} style={{ maxHeight: 130, paddingRight: 20 }}>
                            {parse(disclaimer)}
                        </SimpleBar>
                    </aside>
                }
            </div>
        </VisibilitySensor>
    )
}