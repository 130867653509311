import React, { useContext } from 'react'
import { context } from "../../Context";
import { TOGGLE_MODAL } from "../../constants";

import "./style.scss";

export default function ZoomButton({imagePath, imageWidth,compress = ""}) {
    const { state, dispatch } = useContext(context);

    const openModal = (type, imagePath, imageWidth ,compress ) => {
        document.body.classList.add('modal-active');
        dispatch({
            type: TOGGLE_MODAL,
            payload: {
                type: type,
                content: imagePath,
                imageWidth: imageWidth,
                compress: compress
            }
        });
    }
    return (
        <button className='ZoomButton' onClick={() => openModal('zoom', imagePath, imageWidth ,compress)} aria-label="Zoom" />
    )
}