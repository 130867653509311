import React from 'react'
import { Helmet } from 'react-helmet-async';

function Meta({data}) {
    return (
        <Helmet>
            <title>{data.seoMetaData.Title}</title>
            <meta name="description" content={data.seoMetaData.Description}></meta>
            <meta name="keywords" content={data.seoMetaData.Keywords} />

            <meta property="og:title" content={data.seoOg.OgTitle} />
            <meta property="og:description" content={data.seoOg.OgDescription} />
            <meta property="og:image" content={data.seoOg.OgImage.Source} />

            <meta property="twitter:title" content={data.seoOg.OgTitle} />
            <meta property="twitter:description" content={data.seoOg.OgDescription} />
            <meta property="twitter:image" content={data.seoOg.OgImage.Source} />
        </Helmet>
    )
}

export default Meta;