import React, { useContext } from 'react'
import { context } from "../../Context";
import iconCompass from '../../images/icons/compass.svg'
import iconCompassTC from '../../images/icons/compass_tc.svg'

import "./style.scss";

export default function CompassIcon({degree}) {
    const { state, dispatch } = useContext(context);
    return (
        <div className="compassIcon">
            {
                state.language == 'en' &&
                <img src={iconCompass} style={{transform: `rotate(${degree}deg)`}} />
            }
            {
                (state.language == 'tc' || state.language == 'sc') &&
                <img src={iconCompassTC} style={{transform: `rotate(${degree}deg)`}} />
            }
        </div>
    )
    
}