import React from "react"
import useFetchData from '../../hooks/use-fetchData'
import SkeletonLoader from '../../components/SkeletonLoader'
import Meta from '../../components/Meta'
import ChapterBanner from '../../components/ChapterBanner';
import DesignerImageRow from '../../components/DesignerImageRow';

import "./style.scss";

export default function Masters() {
    const { seoData, pageData, isLoading } = useFetchData('masters');

    if (isLoading) {
        return (
            <SkeletonLoader />
        );
    }

    return (
        <>
            <Meta data={seoData} />
            {
                pageData.map((component, i) => {
                    if (component.type == "chapterBannerRow") {
                        return (
                            <ChapterBanner key={i} data={component.data} prev="club-lucca" next="gallery" />
                        )
                    }

                    if (component.type == "designerImageRow") {
                        return (
                            <DesignerImageRow key={i} data={component.data} />
                        )
                    }
                })
            }
        </>
    )
}