import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor';
import parse from 'html-react-parser';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import SlidePrevButton from '../../components/SlidePrevButton'
import SlideNextButton from '../../components/SlideNextButton'
import ImageWithDisclaimer from '../../components/ImageWithDisclaimer';

import "./style.scss";

export default function DesignerImageRow({data}) {
    const [visibility, setVisibility] = useState(false);

    const [totalSlides, setTotalSlides] = useState(1)
    const [currentSlide, setCurrentSlide] = useState(1)

    return (
        <div className={`designerImageRow ${data.textOnLeftSide == true? 'flip': ''}`}>
            <div className='left'>
                <Swiper
                    onSwiper={(swiper) => {
                        setTotalSlides(swiper.slides.length)
                    }}
                    onSlideChange={(e) => {
                        setCurrentSlide(e.realIndex + 1)
                    }}
                    >
                        {
                            totalSlides > 1 && 
                                <>
                                    <SlidePrevButton className={`${currentSlide == 1? 'disabled': ''}`}  />
                                    <SlideNextButton className={`${currentSlide == totalSlides? 'disabled': ''}`}/>
                                </>
                        }
                        {
                            data.imageWithDisclaimers.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <LazyLoadComponent>
                                        <ImageWithDisclaimer data={item} />
                                    </LazyLoadComponent>
                                </SwiperSlide>
                            ))
                                
                            
                        }
                </Swiper>
            </div>
            <VisibilitySensor partialVisibility active={!visibility} onChange={(isVisible) => {
                setVisibility(isVisible)
            }}>
                <div className={`right ${visibility === true? 'transition': ''}`}>
                    <div className='paragraph'>
                        {
                            data.paragraph.specialist &&
                                <h2>{data.paragraph.specialist}</h2>
                        }
                        {
                            data.paragraph.designerName &&
                                <img src={data.paragraph.designerName.Source + '?format=webp'} />
                        }
                        {
                            data.paragraph.contentText &&
                                <>
                                    {parse(data.paragraph.contentText)}
                                </>
                        }
                        {
                            data.paragraph.signature && data.paragraph.signature.Source != "" &&
                                <img src={data.paragraph.signature.Source + '?format=webp'} className="signature" width={data.paragraph.signature.Width} height={data.paragraph.signature.Height} />
                        }
                    </div>
                </div>
            </VisibilitySensor>
        </div>
    )
}