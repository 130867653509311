import React from 'react'
import parse from 'html-react-parser';

import "./style.scss";

export default function ParagraphRow({data}) {
    return (
        <div className='ParagraphRow'>
            {
                <>
                    <h2>{parse(data.header)}</h2>
                    {parse(data.contentText)}
                </>
            }
        </div>
    )
}