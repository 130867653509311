import { useEffect, useState, useContext} from 'react';
import { useLocation } from "react-router-dom";
import { context } from "../Context";
import axios from 'axios';

const loadImage = path => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = path
        img.onload = () => {
            resolve(img)
        }
        img.onerror = e => {
            reject(e)
        }
    })
}

  
const useFetchData = (path) => {
    const { state, dispatch } = useContext(context);
    const location = useLocation();

    const [pageData, setPageData] = useState({});
    const [seoData, setSeoData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: response } = await axios.get(process.env.API_URL + '/' + path + '?lang=' + state.language);
                setPageData(response.pageData);
                setSeoData(response.seoData);

                if (response.pageData[0].type == 'chapterBannerRow') {
                    try {
                        const imgDesktop = await loadImage(response.pageData[0].data.imageWithDisclaimers.imageDesktop.Source + '?format=webp')
                        const imgTablet = await loadImage(response.pageData[0].data.imageWithDisclaimers.imageTablet.Source + '?format=webp')
                        const imgMobile = await loadImage(response.pageData[0].data.imageWithDisclaimers.imageMobile.Source + '?format=webp')
                        
                    } catch (e) {
                        console.log(e)
                    }
                }
                
            } catch (error) {
                console.error(error)
            }
            
            setIsLoading(false);
            };
        if (state.language != undefined) {
            fetchData();
        }
    }, [state.language, location]);

    return {
        seoData,
        pageData,
        isLoading,
    };
};

export default useFetchData;