import React, {useEffect, useState, useContext} from "react"
import { context } from "./Context";
import {
    Outlet,
    useNavigate,
    useLocation,
    useParams
} from "react-router-dom";
import parse from 'html-react-parser';
import useBackListener from './hooks/use-BackListener'

import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet-async';

import iconLandscape from './images/icons/iconLandscape.svg'

import NavSiblings from './components/NavSiblings';
import Header from './components/Header';
import Footer from './components/Footer';
import Modal from './components/Modal';
import { SET_LANGUAGE, CURRENT_PAGE, RESPONSIVE_MODE, TOGGLE_MODAL } from "./constants";

export default function Layout() {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(context);
    
    let navigate = useNavigate();
    let params = useParams();
    const location = useLocation();
    const { i18n } = useTranslation();

    const allowedLanguage = ["tc", "sc", "en"]
    useEffect(() => {
        if (params.lang === undefined || params.lang === "tc") {
            dispatch({
                type: SET_LANGUAGE,
                payload: "tc"
            });
        }

        if (params.lang === "en") {
            dispatch({
                type: SET_LANGUAGE,
                payload: "en"
            });
        }

        if (params.lang === "sc") {
            dispatch({
                type: SET_LANGUAGE,
                payload: "sc"
            });
        }
        if (!allowedLanguage.includes(params.lang)) {
            dispatch({
                type: SET_LANGUAGE,
                payload: "tc"
            });
            navigate("/tc/");
        }
        i18n.changeLanguage(params.lang)
    }, [params.lang]);

    // Set the page class to the wrapper for styles fine tuning
    useEffect(() => {
        dispatch({
            type: CURRENT_PAGE,
            payload: location.pathname.split("/").pop()
        });
    }, [location]);

    const [landscapeMode, setLandscapeMode] = useState(false)
    useEffect(() => {
        if (!!navigator.maxTouchPoints && window.matchMedia("(orientation: landscape)").matches) {
            document.body.classList.add('modal-active');
            setLandscapeMode(true)
        }
    }, [params.lang]);
    const closeModal = (e) => {
        document.body.classList.remove('modal-active');
        setLandscapeMode(false)
    }

    useEffect(() => {
        const handleWindowResize = () => {
            if (window.innerWidth <= 1194 || (!!navigator.maxTouchPoints && window.matchMedia("(orientation: landscape)").matches)) {
                dispatch({
                    type: RESPONSIVE_MODE,
                    payload: true
                });
            } else {
                dispatch({
                    type: RESPONSIVE_MODE,
                    payload: false
                });
            }
        };
        window.addEventListener("load", handleWindowResize);
        window.addEventListener("resize", handleWindowResize);
       
        return () => {
            window.removeEventListener("load", handleWindowResize);
            window.removeEventListener("resize", handleWindowResize);
        }
    }, []);

    useBackListener(({ location }) => {
        document.body.classList.remove('modal-active');
        dispatch({
            type: TOGGLE_MODAL,
            payload: false
        })
    });



    return (
        <>
            <Helmet htmlAttributes={
                {
                    lang: (state.language == 'en'? 'en': state.language == 'tc'? 'zh-Hant': 'zh-Hans'),
                }
            }>
                <meta name="description" content="Helmet application" />
            </Helmet>
            {
                landscapeMode &&
                <div className="landscapeAlert">
                    <img src={iconLandscape} width="100" height="100" />
                    {parse(t("LandscapeMessage"))}
                    <button onClick={() => closeModal()} />
                </div>
            }
            <Header />
            <div className={`page_` + state.current_page}>
                <Outlet />
            </div>
            <NavSiblings />
            <Footer />
            <Modal />
        </>
    )
}